<template>
  <div class="base flex" ref="containerDiv">
    <Lnb v-if="navVisible && userType == 'Admin' && userType != null" />
    <div class="main">
      <Gnb v-if="gnbVisible" />
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import { format } from "@/mixins/format";
import { setNavbar } from "@/mixins/setNavbar";
import { setFooter } from "@/mixins/setFooter";
import { setGnb } from "@/mixins/setGnb";
import Lnb from "@/components/Lnb";
import Gnb from "@/components/Gnb";
import { mapState } from "vuex";
export default {
  mixins: [format, setNavbar, setFooter, setGnb],
  components: { Lnb, Gnb },
  mounted() {

    //mouseover 상태바(아래 URL) 없애기
    this.$nextTick(function (){
      var atags = this.$refs.containerDiv.querySelector('a');
      if(atags != null) {
        atags.addEventListener('mouseover', function() {
          //console.log("zzz")
          var href = this.getAttribute('href') || this.getAttribute("data-href");
          //console.log(href, this)
          this.addEventListener('click', function () {
            window.location.href = href;
          })
          this.setAttribute('data-href', href)//href에 지정된 링크 연결을 유지 시킵니다.
          this.removeAttribute('href');
        })
      }
    })

    if (this.$route.query.new) {
      this.setNavbar(false);
      this.setGnb(false);
    }
    // this.setNavbar(false);
    this.setFooter(false);
  },
  computed: {
    ...mapState({
      navVisible: state => state.store.navVisible,
      gnbVisible: state => state.store.gnbVisible,
      userType: state => state.store.userType,
    }),
  },
  beforeDestroy() {
    this.setNavbar(true);
    this.setGnb(true);
    this.setFooter(true);
  },
};
</script>
